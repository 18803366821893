import * as React from "react";
import { withPrismicUnpublishedPreview } from "gatsby-plugin-prismic-previews";

const NotFoundPage = () => {
  if (typeof window !== "undefined") {
    window.location = "/";
  }
  return <div className={"container text-center mt-5 pt-5"}></div>;
};

export default withPrismicUnpublishedPreview(NotFoundPage);
